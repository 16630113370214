exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-guidelines-tsx": () => import("./../../../src/pages/guidelines.tsx" /* webpackChunkName: "component---src-pages-guidelines-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-random-tsx": () => import("./../../../src/pages/random.tsx" /* webpackChunkName: "component---src-pages-random-tsx" */),
  "component---src-podcast-podcast-ikigai-dalam-islam-mdx": () => import("./../../../src/podcast/podcast/ikigai-dalam-islam.mdx" /* webpackChunkName: "component---src-podcast-podcast-ikigai-dalam-islam-mdx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-podcast-tsx": () => import("./../../../src/templates/podcast.tsx" /* webpackChunkName: "component---src-templates-podcast-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

